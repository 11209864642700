<template>
	<v-dialog persistent :value="dialog" width="700">
		<AppDialog
			v-model="loading"
			:heading="{
				label: editedItem ? $t('app.edit_group') : $t('app.create_group'),
			}"
			:action="{
				label: 'app.save',
				event: 'save',
			}"
			@save="save()"
			@close="onBeforeClose()"
		>
			<template v-slot:dialog-content>
				<v-breadcrumbs
					v-if="breadcrumbs.length > 1"
					:items="breadcrumbs"
					class="px-0 pt-0"
				>
					<template v-slot:item="{ item }">
						<v-breadcrumbs-item
							:class="{
								'info--text ': true,
							}"
						>
							{{ $t(item.text) }}
						</v-breadcrumbs-item>
					</template>
					<template v-slot:divider>
						<v-icon>{{
							$vuetify.rtl
								? $vuetify.icons.values.left_chevron
								: $vuetify.icons.values.right_chevron
						}}</v-icon>
					</template>
				</v-breadcrumbs>

				<v-form ref="form">
					<!-- Group Name -->
					<v-text-field
						v-model="group.name"
						:label="$t('app.name')"
						outlined
						:rules="[rules.required]"
						color="greenAccent2"
					></v-text-field>
					<!-- Project Name -->
					<v-text-field
						v-model="group.project_name"
						:label="$t('app.project_name')"
						outlined
						:rules="[rules.required]"
						color="greenAccent2"
					></v-text-field>
					<!-- language -->
					<template v-if="isAdmin">
						<v-select
							v-model="group.group_lang"
							:items="$defines.LOCALES"
							item-text="name"
							item-value="code"
							outlined
							color="greenAccent2"
							background-color="white"
							:label="$t('app.language')"
							:rules="[rules.required]"
						>
						</v-select>

						<v-row>
							<!-- menu -->
							<v-col>
								<v-select
									v-model="group.menu"
									:items="menus"
									item-text="title"
									item-value="id"
									outlined
									color="greenAccent2"
									background-color="white"
									:label="$t('app.menu')"
									:rules="[rules.required]"
								>
								</v-select>
							</v-col>
							<!-- plan -->
							<v-col>
								<v-select
									v-model="group.plan"
									:items="plans"
									item-text="name"
									item-value="id"
									outlined
									color="greenAccent2"
									background-color="white"
									:label="$t('app.plan')"
									:rules="[rules.required]"
								>
								</v-select>
							</v-col>
							<!-- paper_schemes -->
							<v-col>
								<v-select
									v-model="group.paper_scheme"
									:items="paper_schemes"
									item-text="name"
									item-value="name"
									outlined
									color="greenAccent2"
									background-color="white"
									:label="$t('app.paper_scheme')"
									:rules="[rules.required]"
								>
								</v-select>
							</v-col>
						</v-row>
						<!-- Ownership -->
						<v-text-field
							v-if="!editedItem || !editedItem.id"
							v-model="group.owner"
							type="number"
							:placeholder="$t('app.owner_id')"
							outlined
							:rules="[rules.required]"
							color="greenAccent2"
						>
						</v-text-field>
					</template>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import rules from "@/utils/validation";
import { mapState, mapGetters } from "vuex";

export default {
	name: "PopulateDialog",

	props: {
		dialog: {
			required: true,
		},
		editedItem: {
			required: false,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			plans: [],
			menus: [],
			paper_schemes: [],
			group: {
				name: null,
				project_name: null,
				group_lang: null,
				plan: null,
				menu: null,
				paper_scheme: null,
				owner: null,
			},
			ownershipOptions: [
				{
					name: "app.myself",
					value: 1,
				},
				{
					name: "app.another_user",
					value: 2,
				},
			],
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapState({
			current_group_id: (state) => state.group.current_group_id,
			breadcrumbs: (state) => state.group.breadcrumbs,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
			isAdmin: "user/isAdmin",
		}),
	},

	created() {
		this.init();
	},

	mounted() {
		if (this.editedItem) {
			this.setupToEdit();
		}
	},

	methods: {
		async init() {
			// get menus list
			this.menus = await this.$store.dispatch("research/listMenus");
			// get plans list
			let res = await this.$store.dispatch("research/listPlans");
			if (res) {
				this.plans = res.results;
			}
			// get paper_schemes
			this.paper_schemes = await this.$store.dispatch(
				"research/listPaperSchemes",
			);
		},

		setupToEdit() {
			try {
				// Assign manually because of properties nomenclature differences
				// in saving and getting APIs.
				this.group.name = this.editedItem.name;
				this.group.project_name = this.editedItem.project_name;
				this.group.group_lang = this.editedItem.group_lang;
				this.group.owner = this.editedItem.owner;
				this.group.plan = isNaN(this.editedItem.group_plan)
					? null
					: this.editedItem.group_plan;
				this.group.menu = isNaN(this.editedItem.group_menu)
					? null
					: this.editedItem.group_menu;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async save() {
			try {
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				let payload = this.group;
				// sanitize the payload
				payload.name = this.$xss.sanitize(this.group.name);
				payload.project_name = this.$xss.sanitize(this.group.project_name);

				let to_edit = this.editedItem && this.editedItem.id;
				let action_name = to_edit ? "group/update" : "group/create";
				let event_name = to_edit ? "on-edit" : "on-create";

				// prepare payload
				if (to_edit) {
					payload.group_id = this.editedItem.id;
				} else {
					payload.parent = this.current_group_id;
					payload.owner = this.group.owner;
				}

				// dispatch action
				await this.$store.dispatch(action_name, payload);

				// emit event to parent
				this.$emit(event_name, () => {
					this.loading = false;
					this.$refs.form.reset();
				});
			} catch (error) {
				this.loading = false;
				this.$announce.error(error);
			}
		},

		async onBeforeClose() {
			// Reset the form
			this.$refs.form.reset();
			// Close dialog
			this.$emit("close");
		},
	},
};
</script>

<style></style>

<template>
	<AssociatedGroups></AssociatedGroups>
</template>

<script>
import AssociatedGroups from "@/components/shared/group/Index.vue";

export default {
	name: "MentorGroups",

	components: {
		AssociatedGroups,
	},
};
</script>

<style></style>

<template>
	<v-breadcrumbs
		v-if="breadcrumbs && breadcrumbs.length"
		:items="breadcrumbs"
		class="ps-3 pt-0 pb-2"
	>
		<template v-slot:item="{ item }">
			<v-breadcrumbs-item
				@click="onAfterClick(item)"
				:class="{
					'info--text title ': true,
					'cursor-wr': item.id != current_group_id,
					'font-weight-bold': item.id == current_group_id,
				}"
			>
				{{ $te(item.text) ? $t(item.text) : item.text }}
			</v-breadcrumbs-item>
		</template>
		<template v-slot:divider>
			<v-icon size="25">{{
				$vuetify.rtl
					? $vuetify.icons.values.left_chevron
					: $vuetify.icons.values.right_chevron
			}}</v-icon>
		</template>
	</v-breadcrumbs>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "GroupBreadcrumbs",

	computed: {
		...mapState({
			breadcrumbs: (state) => state.group.breadcrumbs,
			current_group_id: (state) => state.group.current_group_id,
		}),

		componentName() {
			return "GroupData";
		},
	},

	methods: {
		onAfterClick(payload) {
			// If clicking on current, do not respond
			if (this.current_group_id == payload.id) return;
			// Else, remove all after requested breadcrumb
			this.$store.commit("group/REMOVE_FROM_BREADCRUMBS", payload);
		},
	},
};
</script>

<style></style>

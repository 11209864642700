<template>
	<div>
		<template v-if="(!list || !list.length) && !current_group_id">
			<v-container class="pt-15">
				<NoData
					heading="app.no_groups_yet"
					@init="dialog = true"
					:show_action="isAdmin"
				></NoData>
			</v-container>
		</template>

		<template v-else>
			<!-- Displaying nestedness of group using breadcrumbs -->
			<Breadcrumbs></Breadcrumbs>

			<!-- Display the list -->

			<v-card-text
				v-if="!current_group_id"
				class="ps-3 greyDarken4--text title"
			>
				{{ $t("app.groups_you_own") }}
			</v-card-text>
			<v-card-text
				v-if="current_group_id && (isAdmin || (!isAdmin && list && list.lengt))"
				class="ps-3 greyDarken4--text title"
				v-html="
					$t('app.groups_added_to') +
						` <span class='text-decoration-underline'>${currentGroup.text}</span>`
				"
			></v-card-text>

			<v-card
				v-for="(item, index) in list"
				:key="index"
				width="300"
				height="220"
				outlined
				tile
				class="overflow-hidden mx-2 mt-2 d-inline-block"
				:style="{ background: generateRandomColor() }"
			>
				<v-card-text class="pb-1">
					<v-row no-gutters>
						<v-col class="text-truncate">
							<div class="subtitle-1 greyDarken4--text font-weight-bold ">
								{{ item.name }}
							</div>
							<div class="subtitle-2 greyDarken4--text">
								{{
									item.project_name ? item.project_name : $t("app.project_name")
								}}
							</div>
						</v-col>
						<v-col v-if="isAdmin" sm="2" align="end">
							<v-btn icon fab small @click="prepareEditing(item)">
								<v-icon>$vuetify.icons.values.edit</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-text class="greyDarken4--text">
					<v-row no-gutters>
						<v-col>
							{{ $t("app.total_students") }}
						</v-col>
						<v-col align="end" sm="4">
							{{ item.students_count }}
						</v-col>
					</v-row>
					<v-row no-gutters class="mt-1">
						<v-col>
							{{ $t("app.language") }}
						</v-col>
						<v-col align="end" sm="4">
							{{ getLanguageName(item.group_lang) }}
						</v-col>
					</v-row>
				</v-card-text>

				<v-footer absolute bottom color="transparent">
					<v-btn
						v-if="isAdmin"
						icon
						fab
						small
						@click="prepareDeletion(item.id)"
					>
						<v-icon>$vuetify.icons.values.delete</v-icon>
					</v-btn>
					<v-spacer></v-spacer>
					<AppButton
						label="app.dive_in"
						color="white"
						:small="true"
						:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
						:class="['font-weight-bold']"
						@click="diveIn(item)"
					></AppButton>
				</v-footer>
			</v-card>

			<!-- Show add group only if admin -->
			<v-card
				v-if="isAdmin"
				width="300"
				height="220"
				outlined
				tile
				class="overflow-hidden mx-2 mt-2 d-inline-block card-wr"
				@click="dialog = true"
			>
				<v-row class="full-height-wr" align="center">
					<v-col align="center">
						<v-icon large color="amberDarken1"
							>$vuetify.icons.values.plus</v-icon
						>
						<div class="title mt-2 amberDarken1--text font-weight-bold">
							{{ $t("app.add_group") }}
						</div>
					</v-col>
				</v-row>
			</v-card>
		</template>

		<!-- Create Group Dialog -->
		<PopulateDialog
			v-if="dialog"
			:dialog="dialog"
			:editedItem="group_to_be_edit"
			@on-edit="onGroupEditOrCreate"
			@on-create="onGroupEditOrCreate"
			@close="onDialogClose"
		></PopulateDialog>

		<TheConfirmation
			v-if="delete_dialog"
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="deleteGroup()"
		></TheConfirmation>
	</div>
</template>

<script>
import { generateRandomColor, sortArrayOfObjects } from "@/utils/helpers";
import { mapState, mapGetters } from "vuex";
import PopulateDialog from "@/components/shared/group/PopulateDialog.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";
import NoData from "@/components/shared/NoData.vue";
import Breadcrumbs from "@/components/shared/group/Breadcrumbs.vue";

export default {
	name: "GroupsList",

	data() {
		return {
			generateRandomColor,
			list: null,
			dialog: false,
			delete_dialog: false,
			selected_group_id: null,
			group_to_be_edit: null,
		};
	},

	components: {
		PopulateDialog,
		TheConfirmation,
		NoData,
		Breadcrumbs,
	},

	computed: {
		...mapState({
			breadcrumbs: (state) => state.group.breadcrumbs,
			current_group_id: (state) => state.group.current_group_id,
		}),

		...mapGetters({
			getLanguageName: "globals/getLanguageName",
			isAdmin: "user/isAdmin",
		}),

		currentGroup() {
			return this.breadcrumbs[this.breadcrumbs.length - 1];
		},
	},

	watch: {
		current_group_id: {
			handler() {
				this.init();
			},
			immediate: true,
		},
	},

	methods: {
		async init() {
			try {
				this.$loader.start();
				let response;

				// If current group is set means breadcrumbs are dived in
				if (this.current_group_id) {
					response = await this.$store.dispatch("group/listSubGroups", {
						group_id: this.current_group_id,
					});
				}
				// Else, if admin then get all groups otherwise only owned groups
				else {
					response = this.isAdmin
						? await this.$store.dispatch("group/listSubGroups", {
								group_id: "top_level",
						  })
						: await this.$store.dispatch("group/list");
				}

				this.list = sortArrayOfObjects(response, "id");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		diveIn(payload) {
			this.$store.commit("group/PUSH_TO_BREADCRUMBS", payload);
		},

		prepareEditing(payload) {
			this.group_to_be_edit = payload;

			this.dialog = true;
		},

		prepareDeletion(group_id) {
			this.selected_group_id = group_id;

			this.delete_dialog = true;
		},

		async deleteGroup() {
			await this.$store.dispatch("group/delete", {
				group_id: this.selected_group_id,
			});

			this.selected_group_id = null;

			this.delete_dialog = false;

			await this.init();
		},

		async onGroupEditOrCreate(cb) {
			await this.init();

			this.onDialogClose();

			cb();
		},

		onDialogClose() {
			this.group_to_be_edit = null;
			this.dialog = false;
		},
	},
};
</script>

<style scoped>
.card-wr {
	border: 2px dashed var(--v-amberDarken1-base);
}
</style>

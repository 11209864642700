<template>
	<v-card flat height="100%" class="wr_grey_1">
		<!-- Displating list-->
		<List></List>

		<!-- Displaying students in the current group -->
		<div>
			<Students></Students>
		</div>
	</v-card>
</template>

<script>
import List from "@/components/shared/group/List.vue";
import Students from "@/components/shared/student/list/Index.vue";

export default {
	name: "Group",

	components: {
		List,
		Students,
	},
};
</script>
